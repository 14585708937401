import { useEffect } from "react";

import { useRouter } from "next/router";

const useSetPageTitle = (title: string) => {
  const router = useRouter();

  const setPageTitle = () => {
    if (title) {
      document.title = `${title} - Navirec`;
      return;
    }

    document.title = `Navirec`;
  };

  useEffect(() => {
    setPageTitle();
    router.events.on("routeChangeComplete", setPageTitle);

    return () => {
      router.events.off("routeChangeComplete", setPageTitle);
    };
  }, [title]);
};

export default useSetPageTitle;
