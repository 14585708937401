import { t } from "@lingui/macro";
import { PageProps } from "layout";

import ErrorPage from "components/error-page";

import useSetPageTitle from "hooks/set-page-title";

const Custom404: PageProps = () => {
  useSetPageTitle("Page notfound");

  return <ErrorPage error={{ code: "notfound", message: t`Sorry, the page you visited does not exist` }} />;
};

export default Custom404;
Custom404.Layout = "Blank";
